.column-flex {
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 105px);
}
.container {
  flex: 1;
}
.search-plate {
  background: #fff;
  padding: 25px 30px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}
.search-plate .ant-btn {
  margin-left: 10px;
}
.search-plate .ant-btn-primary {
  background: #4687FE;
}
.search-plate :deep .ant-select {
  width: 200px !important;
  margin-right: 10px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.flex-left {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.flex-left > div {
  display: flex;
  align-items: center;
  margin: 0 20px 15px 0;
}
.flex-left > div > span {
  text-wrap: nowrap;
}
.flex-left > .ant-btn {
  margin-bottom: 15px;
}
.ant-form {
  display: flex;
  flex-flow: row wrap;
}
.ant-form :deep .ant-form-item {
  display: flex;
  flex-flow: column nowrap;
  margin: 0 35px 12px 0;
  height: 64px;
}
.ant-form :deep .ant-form-item:nth-child(1) {
  width: 100%;
}
.ant-form :deep .ant-form-item .ant-input {
  border-color: #EBEDF0;
}
:deep .ant-form-item-label > label {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.85);
}
.drawer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer-footer .tips {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 12px;
  color: #EC1818;
}
:deep .ant-form-item-control-input-content > div {
  width: 210px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #E7E7E7;
  border-radius: 2px;
  padding: 0 10px;
  color: #7E7F88;
  white-space: nowrap;
  overflow: hidden;
}
:deep .ant-form-item-control-input-content .remark {
  white-space: normal;
  padding: 6px 10px;
}
.tip {
  position: absolute;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 12px;
  color: #F12929;
}
.remarks {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
